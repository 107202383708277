// getDrmInfo.js
'use strict';
import {
  getIsOnApple,
  getIsEdgeChromium,
  getIsMicrosoftEdge,
  getIsOnMobile,
  getIsChrome,
  getIsOnAndroidWebview,
} from './getUserAgent.js';

import {
  DrmRobustnessPriority,
  COM_APPLE_FPS,
  COM_WIDEVINE_ALPHA,
  COM_MICROSOFT_PLAYREADY,
  COM_MICROSOFT_PLAYREADY_HARDWARE,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
  ORG_W3_CLEARKEY,
} from '../resource/drmConstants.js';
import { getShouldTrackUnavailable as getShouldTrackMixpanelUnavailable } from '../resource/mixpanel.js';

const isServer = typeof window === 'undefined';
const encoder = !isServer && window.TextEncoder ? new TextEncoder() : undefined;

export const videoContentType = 'video/mp4; codecs="avc1.640028"';

/**
 * Get drm info
 */
export const getKeySystem = () => {
  if (getIsOnApple()) {
    if (
      (getIsChrome() && !getIsOnMobile()) ||
      getIsEdgeChromium() ||
      getIsMicrosoftEdge()
    ) {
      return COM_WIDEVINE_ALPHA;
    }
    return COM_APPLE_FPS;
  }
  if (getIsEdgeChromium() || getIsMicrosoftEdge()) {
    return COM_MICROSOFT_PLAYREADY;
  }
  return COM_WIDEVINE_ALPHA;
};

export const getRobustness = ({ robustnessData }) => {
  const keySystem = getKeySystem();
  if (keySystem === COM_APPLE_FPS) {
    return '';
  } else if (keySystem === COM_WIDEVINE_ALPHA) {
    return robustnessData.widevine;
  } else if (keySystem === COM_MICROSOFT_PLAYREADY) {
    return robustnessData.playReady;
  }
};

/**
 * Compute license signature.
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 * @kind action
 * @param {String|TypedArray} {body} - license request payload.
 * @param {string} {[token = '']} - user token.
 * @return {Promise} Action promise.
 */
export const getLicenseSignature = async ({ body, token = '' }) => {
  if (!encoder) {
    return '';
  }

  let bodyArray = body;
  if ('string' === typeof body || body instanceof String) {
    // PlayReady
    bodyArray = encoder.encode(body);
  } else if (!(body instanceof Uint8Array)) {
    // WideVine
    bodyArray = new Uint8Array(body);
  }

  const tokenUint8Array = encoder.encode(token);
  const shouldTrackMixpanelUnavailable =
    await getShouldTrackMixpanelUnavailable();
  const mixpanelAvailabilityArray = encoder.encode(
    shouldTrackMixpanelUnavailable ? '0' : ''
  );

  const jointedUint8Array = new Uint8Array(
    bodyArray.length + tokenUint8Array.length + mixpanelAvailabilityArray.length
  );
  jointedUint8Array.set(bodyArray);
  jointedUint8Array.set(tokenUint8Array, bodyArray.length);
  jointedUint8Array.set(
    mixpanelAvailabilityArray,
    bodyArray.length + tokenUint8Array.length
  );
  const hashBuffer = await crypto.subtle.digest('SHA-256', jointedUint8Array);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

/**
 * Get lowest playready hardware priority
 * @return {number} Return lowest playready hardware priority.
 */
export const getLowestPlayreadyHardwarePriority = () => {
  const priorities = [
    COM_MICROSOFT_PLAYREADY,
    COM_MICROSOFT_PLAYREADY_HARDWARE,
    COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
    COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
  ]
    .map(keySystem => DrmRobustnessPriority[keySystem]['3000'])
    .filter(priority => priority != null)
    .sort((a, b) => a - b);
  return priorities[0];
};

/**
 * Get drm capability testcases
 * @return {array} - Return drm capability testcases
 */
export const getDrmCapabilityTestcases = () => {
  const keySystem = getKeySystem();
  const priorityObject = DrmRobustnessPriority[keySystem] || {};
  const testcases = Object.keys(priorityObject).map(robustness => ({
    keySystem,
    robustness,
  }));
  // Chromium edge support both playready and widevin. needs to test two key systems.
  // Playready has multiple key system strings, should add all key system strings and robustness.
  if (COM_MICROSOFT_PLAYREADY === keySystem) {
    [
      COM_MICROSOFT_PLAYREADY_HARDWARE,
      COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
      COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
      COM_WIDEVINE_ALPHA,
    ].forEach(keySystemString => {
      Object.keys(DrmRobustnessPriority[keySystemString]).forEach(robustness =>
        testcases.push({
          keySystem: keySystemString,
          robustness,
        })
      );
    });
  }

  // clearkey
  Object.keys(DrmRobustnessPriority[ORG_W3_CLEARKEY]).forEach(robustness => {
    testcases.push({ keySystem: ORG_W3_CLEARKEY, robustness });
  });

  // Android WebView has limited DRM capabilities, so we only test robustness levels
  // that are equal to or lower than Widevine's SW_SECURE_CRYPTO level
  if (getIsOnAndroidWebview()) {
    return testcases.filter(({ keySystem, robustness }) => {
      return (
        DrmRobustnessPriority[keySystem][robustness] <=
        DrmRobustnessPriority[COM_WIDEVINE_ALPHA]['SW_SECURE_CRYPTO']
      );
    });
  }

  return testcases;
};
